var page_home_loaded = 1;
global.page_home_loaded = page_home_loaded;

global.jQuery = require('jquery');
var slickInit = require('slick-carousel');

page_home = {
	load: function(){
		this.homeSlider();
		this.soluzioniSlider();
		this.partnersSlider();
	},
	homeSlider: function(){
		jQuery('.homeVisual__slider').slick({
			lazyLoad: 'progressive',
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			fade: false,
			dots: true,
			speed: 450,
			touch: false,
			autoplay: true,
			autoplaySpeed: 4500,
		});
		jQuery('.homeVisual__slider').on('beforeChange',function(){
			siteGlobal.lazyLoad();
		})
	},
	soluzioniSlider: function(){
		jQuery('.homeSol__slider').slick({
			lazyLoad: 'ondemand',
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			fade: false,
			dots: false,
			speed: 450,
			touch: false,
			autoplay: true,
			autoplaySpeed: 4500,
			responsive: [
    			{
    				breakpoint: 767,
    				settings: {
				        slidesToShow: 1,
			      }
    			}
    		]
		});
	},
	partnersSlider: function(){
		jQuery('.homePartner__slider').slick({
			slidesToShow: 4,
			slidesToScroll: 1,
			arrows: true,
			fade: false,
			dots: false,
			speed: 450,
			touch: false,
			variableWidth: true,
			responsive: [
    			{
    				breakpoint: 767,
    				settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1,
				        variableWidth: false,
			      }
    			}
    		]
		});
	}
}

$(window).on('load', function(){
	page_home.load();
})